import React from 'react';
import styles from './MainTitle.module.scss';
import classNames from 'classnames';

const MainTitle = ({ name, description, astafiev }) => {
  return (
    <div
      className={classNames(styles.main_title, { [styles.astafiev]: astafiev })}
    >
      <h1>{name}</h1>
      <div className={styles.line}></div>
      <p>{description}</p>
    </div>
  );
};

export default MainTitle;
