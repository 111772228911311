import React from 'react';
import styles from './Logo.module.scss';
import { ReactComponent as LogoImg } from '../../../assets/img/logo/logo.svg';
import { ReactComponent as LogoColorImg } from '../../../assets/img/logo/logo-color.svg';

const Logo = ({ color }) => {
  return (
    <div className={styles.logo_wrap}>
      <a href='https://ingorod24.ru/' target='_blank' rel='noopener noreferrer'>
        {color ? <LogoColorImg /> : <LogoImg />}
      </a>
    </div>
  );
};

export default Logo;
